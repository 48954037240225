body {
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(24, 23, 23);
  background: radial-gradient(ellipse at bottom, #000000 0%, #1c1c1d 100%);
  background-repeat: no-repeat;
}
.body::-webkit-scrollbar {
  width: 8px;
}
 
.body::-webkit-scrollbar-track {
  background: #3d3c3c; 
  border-radius: 10px;
}

.body::-webkit-scrollbar-thumb {
  background-color: #0f1018; 
  border-radius: 10px;
  border: 2px solid #3d3c3c; 
}

.body::-webkit-scrollbar-thumb:hover {
  background-color: #2c3e91; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
