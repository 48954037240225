.catagoryslider-container {
    margin-top: 5vh;
    width: 95%;
    padding: 20px;
    background-color: #fcfcfc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(247, 244, 244, 0.1);
    z-index: 1;
}

.cattext {
    color: #fffefe;
    font-size: 2em;
    font-weight: 100;
    text-align: center;
    margin-bottom: 20px;
}

.catsliderimg {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.catsliderimg:hover {
    transform: scale(1.05);
}

.product-list-container {
    position: relative;
}
.headercatag{
    background-color: #0c0c0c;
    
}
.slick-slide > div {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.product-item {
    padding: 10px;
    padding-bottom: 30px;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #21212200;
    color: #070707;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.product-item:hover {
    transform: translateY(-10px);
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s;
}

.image-container {
    position: relative;
}

.product-tag {
    position: absolute;
    
    left: 70px;
    background-color: rgba(255, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    font-weight: bold;
}
