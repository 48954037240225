/* Footer.css */

.footer {
    background-color: #222;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
  }
  
  .footer-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  }
  