:root {
    --primary-color: #000000; /* Main background color */
    --nav-color: #000000; /* nav background color */
    --secondary-color: #031b07; /* Secondary background color */
    --accent-color: #202220; /* Accent color for buttons and highlights */
    --specialoffer-color: rgb(255, 255, 255);
    --accent-hover-color: #ffbdbd; /* Accent color on hover */
    --light-color: #ffffff; /* Light text color */
    --dark-color: #121212; /* Dark text color */
    --mid-gray-color: #050705; /* Mid gray color for backgrounds */
    --light-gray-color: #e0e0e0; /* Light gray color for backgrounds */
    --border-color: #cccccc; /* Border color */
    --hover-background-color: #5a5a5a; /* Hover background color */
    --hover-border-color: #36c4c7; /* Hover border color */
    --cart-remove-color: #e63946; /* Color for remove button */
    --cart-remove-hover-color: #ff4444; /* Hover color for remove button */
    --button-disabled-color: #9e9e9e; /* Disabled button color */
    --get-offer-hover-color: #2dce89; /* Get offer button hover color */
    --chat-bg-color: #3d3d3d; /* Chat background color */
    --chat-header-bg-color: #6E7582; /* Chat header background color */
    --chat-body-bg-color: #4a4a4a; /* Chat body background color */
    --chat-message-bg-color: #575757; /* Chat message background color */
    --chat-admin-message-bg-color: #1f2933; /* Admin message background color */
    --chat-user-message-bg-color: #0d0d0d; /* User message background color */
    --chat-bot-message-bg-color: #3a3a3a; /* Bot message background color */
    --unread-count-bg-color: #ef5350; /* Unread count background color */
    --info-form-button-bg-color: #16171b; /* Info form button background color */
    --info-form-button-hover-color: #5063f0; /* Info form button hover color */
}




.homepage-main {
    background-color: var(--primary-color); 
}

/* Navbar */
.navcont.container-fluid {
    background-color: var(--nav-color);
}
.headernav {
    background-color: var(--nav-color);
}

/* SPECIAL OFFERS COMP */
.catagoryslider-container {
    background-color: var(--light-gray-color);
}

/* SPECIAL OFFERS TITLE */
.cattext {
    color: var(--specialoffer-color);
}
.headercatag {
    background-color: var(--secondary-color);
}

.product-item {
    background-color: rgba(33, 33, 34, 0);
    color: var(--dark-color);
}

/* Product cart */
.product {
    border: 1px solid var(--border-color);
    background-color: var(--secondary-color);
}
.get-offer-button {
    background-color: var(--accent-color);
    color: var(--light-color);
}
.get-offer-button:hover {
    background-color: var(--accent-hover-color);
}
.product-name {
    color: var( --specialoffer-color);
}
.product-category {
    color: var( --specialoffer-color);
}
.product-description {
    color: var( --specialoffer-color);
}
.product-image {
    background-color: var(--primary-color);
}

/* OFFERS */
.offers-container {
    background-color: var(--light-gray-color);
}
.sidebar {
    background-color: var(--mid-gray-color);
    color: var(--light-color);
}
.accordion {
    background-color: var(--mid-gray-color);
}
.accordion-button {
    background-color: var( --accent-color);
    color: var(--light-color);
}
.accordion-button:not(.collapsed) {
    color: var(--light-color);
    background-color:var( --accent-color);
}
.accordion-body{
    background-color: var(--secondary-color);
    border: none;
}
.list-group-item {
    background-color: var( --accent-color);
    color: var(--light-color);
}
.list-group-item.active {
    background-color: #6c757d;
    border-color: #6c757d;
}
.list-group-item:hover {
    background-color: #6c757d;
}
.subcategory-list .list-group-item {
    background-color: #6c757d;
    margin-bottom: 5px;
}
.subcategory-list .list-group-item.active {
    background-color: #adb5bd;
    border-color: #adb5bd;
}
.subcategory-list .list-group-item:hover {
    background-color: #adb5bd;
}
.product-list-container {
    background-color: var(--light-color);
}
.product-card {
    background-color: var(--light-color);
    border: 1px solid #dee2e6;
}
.product-card p {
    color: #555;
}
.add-to-cart-button:hover {
    background-color: var(--hover-background-color);
}
.add-to-cart-button:active {
    background-color: var(--hover-border-color);
}
.add-to-cart-button {
    background-color: var(--primary-color);
    color: var(--light-color);
}
.product-detail-name {
    color: var( --specialoffer-color);
}
.product-detail-category {
    color: var( --specialoffer-color);
}
.product-detail-description {
    color: var( --specialoffer-color);
}
.product-detail-price {
    color: #ff4081;
}

/* Product page */
.product-detail {
    background-color: #1e1e1e;
    color: var(--light-color);
}
.product-detail-container {
    background-color: #2e2e2e;
    border: 1px solid #3b3737;
}
.product-detail-image-section {
    background-color: #1a1a1a;
}

/* Cart page */
.cart {
    color: var(--light-color);
    background-color: #2c2c2c;
}
.cart-item-info {
    color: #d1d1d1;
}
.cart-item-quantity {
    border: 1px solid #555;
    background-color: #4c4c4c;
    color: #fafafa;
}
.remove-item-button {
    background-color: var(--cart-remove-color);
    color: var(--light-color);
}
.remove-item-button:hover {
    background-color: var(--cart-remove-hover-color);
}
.get-offer-button {
    background-color: #c26107;
    color: var(--light-color);
}
.get-offer-button.disabled {
    background-color: var(--button-disabled-color);
}
.get-offer-button:not(.disabled):hover {
    background-color: var(--get-offer-hover-color);
}

/* Category banner */
.category-banner {
    background-color: var(--light-gray-color);
}
.category-title {
    color: #333;
}

/* Live chat */
.chat-container {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: var(--chat-bg-color);
}
.chat-header {
    background-color: var(--chat-header-bg-color);
    color: var(--light-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.chat-body {
    background-color: var(--chat-body-bg-color);
}
.messages {
    background-color: var(--chat-message-bg-color);
    border-top: 1px solid #ccc;
}
.message.admin {
    color: var(--light-color);
    background-color: var(--chat-admin-message-bg-color);
}
.message.user {
    background-color: var(--chat-user-message-bg-color);
    color: var(--light-color);
}
.message.bot {
    background-color: var(--chat-bot-message-bg-color);
    color: var(--light-color);
}
.chat-input {
    border-top: 1px solid #ddd;
}
.chat-input button {
    background-color: #0f1018;
    color: var(--light-color);
}
.chat-input button:hover {
    background-color: #2c3e91;
}
.chat-header .unread-count {
    background-color: var(--unread-count-bg-color);
    color: var(--light-color);
}
.info-form input {
    border: 1px solid #ccc;
}
.info-form button {
    background-color: var(--info-form-button-bg-color);
    color: var(--light-color);
}
.info-form button:hover {
    background-color: var(--info-form-button-hover-color);
}
