/* ProductDetail.css */

.product-detail {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
  box-sizing: border-box;
}

.product-detail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #2e2e2e;
  border: 1px solid #3b3737;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  box-sizing: border-box;
}
.product-detail-description p{
  color: #fffefe;
}
.product-detail-image-section {
  flex: 1;
  background-color: #1a1a1a;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-image {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.product-detail-info-section {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.product-detail-name {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.product-detail-category {
  font-size: 18px;
  color: #bbbbbb;
  margin-bottom: 20px;
}

.product-detail-description {
  font-size: 18px;
  margin-bottom: 20px;
  color: #dddddd;
  line-height: 1.5;
}

.product-detail-price {
  font-size: 24px;
  font-weight: bold;
  color: #ff4081;
  margin-bottom: 30px;
}

.add-to-cart-button {
  background-color: #080808;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #777576;
  transform: translateY(-2px);
}

.add-to-cart-button:active {
  background-color: #26553a;
  transform: translateY(0);
}

/* Styling for rich text elements */
.ql-editor {
  background-color: transparent;
  color: #dddddd;
}

.ql-editor ol {
  list-style-type: decimal;
  margin: 10px 0;
  padding-left: 20px; /* Adjust to align numbers if necessary */
}

.ql-editor ul {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
}

.ql-editor li {
  margin: 5px 0;
  padding-left: 0; /* Remove padding to ensure numbers appear next to text */
  background-color: transparent;
  border: none;
}

.ql-editor b {
  font-weight: bold;
}

.ql-editor i {
  font-style: italic;
}

.ql-editor u {
  text-decoration: underline;
}

.ql-editor p {
  margin: 0;
  padding: 5px 0;
}
