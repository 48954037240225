/* ContactPage.css */

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f0f0f0;
  }
  
  .contact-info {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-info h2 {
    margin-bottom: 20px;
  }
  
  .contact-info p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  