/* General Styling */
.products-tab {
  padding: 20px;
  color: #ffffff;
  background-color: #121212;
  overflow-y: auto;
  max-height: 80vh;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

/* Form Styling */
.product-form,
.category-form,
.subcategory-form,
.subsubcategory-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.product-form input[type="text"],
.product-form input[type="number"],
.category-form input[type="text"],
.subcategory-form input[type="text"],
.subsubcategory-form input[type="text"],
select {
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.product-form label,
.category-form label,
.subcategory-form label {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* ReactQuill Editor Styling */
.ql-editor {
  color: black; /* Set the text color to black */
}
.ql-editor p{
  color: black; /* Set the text color to black */
}

/* Button Styling */
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.cancel-btn {
  background-color: #dc3545;
}

.cancel-btn:hover {
  background-color: #c82333;
}

.edit-btn {
  background-color: #ffc107;
}

.edit-btn:hover {
  background-color: #e0a800;
}

/* Modal Styling */
.modal {
  background: #1c1c1c;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
  text-align: center;
  color: #ffffff; /* Ensure text is readable in dark mode */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add some shadow for better visibility */
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.7);
}

/* List Styling */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2a2a2a;
  margin-bottom: 10px;
}

li img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.product-info {
  display: flex;
  gap: 20px;
}

.product-info div {
  display: flex;
  flex-direction: column;
}

.product-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Tree Node Styling */
.tree-node {
  margin-left: 20px;
}

.tree-node-label {
  cursor: pointer;
  padding: 5px;
  background-color: #3a3a3a;
  border-radius: 3px;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
}

.tree-node-label:hover {
  background-color: #555;
}

.tree-node.collapsed {
  display: none;
}
