/* Footer.css */

.footer {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    padding: 20px 0;
    text-align: center;
    position: relative;
    
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    margin: 10px 50px;
  }
  
  .footer-section h2,
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    background-color: transparent;
    margin: 5px 0;
  }
  
  .footer-section ul li a {
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #ddd; /* Lighter shade on hover */
  }
  
  .social-media {
    display: flex;
    justify-content: center;
  }
  
  .social-media li {
    margin: 0 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 20px 0;
    }
  }
  