/* Ecobord.css */

/* General container styles */
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
}

/* Carousel styles */
#header-carousel {
    height: 450px; /* Define the height of the carousel */
    
}
.carousel-indicators{
    margin-top: 20px;
}
.carousel-inner .carousel-item {
    height: 100%;
}

.carousel-inner .carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
 

.carousel-caption {
    bottom: 20px;
    align-content: center;
    text-align: center;
    align-items: center;
}

.carousel-caption h1 {
    font-size: 2.5rem;
    font-weight: bold;
}

.carousel-caption p {
    font-size: 1rem;
    font-weight: 300;
   
}

.carousel-caption .btn {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
}

/* Product offer styles */
.product-offer {
    position: relative;
    overflow: hidden;
    margin-top: 15px;
}

.product-offer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.offer-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.offer-text h6,
.offer-text h3 {
    color: #fff;
    margin-bottom: 10px;
}

.offer-text .btn {
    font-size: 0.9rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    .carousel-caption h1 {
        font-size: 2rem;
    }

    .carousel-caption p {
        font-size: 0.9rem;
    }

    .carousel-caption .btn {
        font-size: 0.9rem;
        padding: 0.4rem 1rem;
    }

    .product-offer {
        height: 150px;
    }
}
