/* loginAdmin.css */
.loginAdmin {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .loginAdmin h2 {
    margin-bottom: 20px;
  }
  
  .loginAdmin div {
    margin-bottom: 15px;
  }
  
  .loginAdmin label {
    display: block;
    margin-bottom: 5px;
  }
  
  .loginAdmin input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .loginAdmin .error {
    color: red;
    margin-bottom: 15px;
  }
  
  .loginAdmin button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .loginAdmin button:hover {
    background-color: #0056b3;
  }
  