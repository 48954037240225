.cardcont {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    background-color: #3837374f;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.card {
    position: relative;
    width: 100%;
    
    height: 200px;
    background: linear-gradient(-45deg, #000000 0%, #000000 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card svg {
    width: 48px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card__image {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.card:hover .card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
}

.card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
}

.card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
}

.card:hover svg {
    scale: 0;
}
