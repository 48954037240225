.cart {
  padding: 20px;
  color: #ffffff;
  width: 100%;
  background-color: #2c2c2c;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #3c3c3c;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
  color: #d1d1d1;
}

.cart-item-quantity {
  width: 50px;
  margin: 10px 0;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #4c4c4c;
  color: #fafafa;
  text-align: center;
}

.remove-item-button {
  background-color: #ff4c4c;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-item-button:hover {
  background-color: #ff1c1c;
  
}

.get-offer-button {
  background-color: #c26107;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-offer-button.disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.get-offer-button:not(.disabled):hover {
  background-color: #218838;
}
