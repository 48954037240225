/* src/CartIcon.css */
.cart-icon {
    position: relative;
    display: inline-block;
    
    scale: 0.8;
    
  }
  
  .cart-counter {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
  }
  