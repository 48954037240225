/* src/Notification.css */
.notification {
  position: fixed;
  margin-bottom: 100px;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50; /* Green */
  color: white;
  padding: 16px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 0;
}

.notification.show {
  opacity: 1;
}
