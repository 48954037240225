@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');
    
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.product {
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  padding: 0;
  padding-bottom: 10px;
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in;
  font-family: 'Raleway', sans-serif;
  background-color: #1c1c1c;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 400px;  /* Set a fixed height to ensure consistency */
}

.product:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: auto;
  max-width: 200px;
  border-radius: 10px;
  transition: transform 0.3s;
  background-color: #000;
  margin: 0 auto;
}

.product:hover .product-image {
  transform: scale(1.05);
}

.product-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px;  /* Adding some padding for better spacing */
}

.product-name {
  padding-top: 10px;
  color: #fff;
  font-size: 1.2em;
}

.product-category {
  color: #cfcfcf;
  font-size: 0.9em;
}

.product-description {
  color: #d8d8d8;
  font-size: 15px;
  font-weight: 300;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 0;
  flex-grow: 1;
}

.get-offer-button {
  cursor: pointer;
  border: none;
  width: 70%;
  height: 35px;
  border-radius: 5px;
  background-color: #8d3400;
  color: #fff;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: auto;  /* Pushes the button to the bottom */
  align-self: center;
}

.get-offer-button:hover {
  background-color: #ff4500;
}

.get-offer-button.adding {
  animation: add-to-cart-animation 1s ease;
}

@keyframes add-to-cart-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    background-color: gray;
  }
  100% {
    transform: scale(1);
  }
}

.product-description b {
  font-weight: bold;
}

.product-description i {
  font-style: italic;
}

.product-description u {
  text-decoration: underline;
}

.cardPro {
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --main-color: #000000;
  --main-focus: #2d8cf0;
  width: 200px;
  height: 500px;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cardPro-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-left: 25px;
  height: 200px;
}

.cardPro-title {
  font-size: 20px;
  font-weight: 500;
 
  color: var(--font-color);
}
.cardProInfo{
  height: 300px;
  overflow: hidden;
}
.cardPro-subtitle {
  color: #000;
  font-size: 15px;
  font-weight: 300;
  display: -webkit-box;        /* Required for -webkit-line-clamp */
  -webkit-box-orient: vertical;/* Required for -webkit-line-clamp */
  overflow: hidden;            /* Hides the overflowing content */
  text-overflow: ellipsis;     /* Adds ellipsis (...) to indicate overflow */
  -webkit-line-clamp: 1;       /* Limits the text to 3 lines */
  line-clamp: 3;               /* Standard property for line clamping */
}
.cardPro-divider {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 50px;
}

.cardPro-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardPro-price span {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color-sub);
}

.cardPro-btn {
  height: 35px;
  width: 100px;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  border-radius: 5px;
  padding: 0 15px;
  transition: all 0.3s;
  justify-content: center;
}

.cardPro-btn svg {
  width: 100%;
  height: 90%;
  margin-bottom: 10px;
  scale: 2.3;
  fill: white;
  transition: all 0.3s;
}

.cardPro-img:hover {
  transform: translateY(-3px);
}

.cardPro-btn:hover {
  border: 2px solid var(--main-focus);
}

.cardPro-btn:hover svg {
  fill: var(--main-focus);
}

.cardPro-btn:active {
  transform: translateY(3px);
}

@media (max-width: 768px) {
  .product {
    max-width: 100%;
    width: auto;
    margin: 20px 10px;
  }
}

@media (max-width: 576px) {
  .product-image {
    max-width: 150px;
  }
  .cardPro {
gap: 0px;
height: 500px;
width: 155px;
  }

  .cardPro-img {
   
    margin-left: 0px;
 
  }
}