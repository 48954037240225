.offers-container {
  background-color: #f8f9fa;
  padding: 20px;
  margin: 20px 0;
  width: 100%;
}

.offers-content {
  display: flex;
  flex-direction: row;
}

.sidebar {
  background-color: #343a40;
  padding: 20px;
  
  border-radius: 5px;
  color: white;
}

@media (max-width: 1000px) { /* For tablets and small screens */
  .sidebar {
    width: 100%;
  }
  .accordion {
    display: flex;
    flex-direction: column; /* Stack accordion items in a column */
  }
}

.accordion {
  background-color: #343a40;
}

.accordion-button {
  background-color: #495057;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: #6c757d;
}

.accordion-item {
  border: none;
}

.accordion-body {
  padding-left: 20px;
}

.list-group {
  gap: 5px;
}

.list-group-item {
  background-color: #495057;
  color: white;
  border: none;
  cursor: pointer;
}

.list-group-item.active {
  background-color: #6c757d;
  border-color: #6c757d;
}

.list-group-item:hover {
  background-color: #6c757d;
}

.subcategory-list {
  padding-left: 20px;
  list-style: none;
}

.subcategory-list .list-group-item {
  background-color: #6c757d;
  margin-bottom: 5px;
}

.subcategory-list .list-group-item.active {
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.subcategory-list .list-group-item:hover {
  background-color: #adb5bd;
}

.product-list-container {
  background-color: #fff;
 
  margin-top: 20px;
  gap: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
 
}

.product-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.product-card {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  margin-bottom: 20px;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.product-card h5 {
  font-size: 18px;
  margin: 10px 0 5px;
}

.product-card p {
  font-size: 14px;
  margin: 0 0 10px;
  color: #555;
}

@media (max-width: 768px) {
  .offers-content {
    flex-direction: column;
  }
  .product-list-container {
    padding: 10px;
  }
}

@media (max-width: 690px) {
  .offers-content {
    flex-direction: column;
  }
  .product-list-container {
    padding: 10px;
  }
  .orange{
    font-size: 3rem;
  }
}
